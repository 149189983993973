import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import Product from "~/components/shop/product/product.js"
import { ROUTES } from "../../../constants.js"
import ScrollAnimation from "react-animate-on-scroll"
import getMergeProducts from "~/helpers/products/getMergedProducts.js"
import shuffle from "lodash/shuffle"

const Products = ({ withSingleTitle, products = {} }) => {
  const productsDatas = useStaticQuery(
    graphql`
      {
        allWpProduct {
          nodes {
            slug
            uri
            product_content {
              productType
            }
            Product {
              fieldGroupName
              productColor
              productId
              productName
              variantName
              productImage {
                sourceUrl
                altText
              }
            }
          }
        }
        allShopifyProduct {
          nodes {
            id
            shopifyId
            description
            title
            availableForSale
            productType
            variants {
              price
              compareAtPrice
              shopifyId
            }
          }
        }
      }
    `
  )

  const [Products, setProducts] = useState([])
  // Homepage: on recupere les produits a mettre en avant de la contrib de la homepage
  // Page produit: on requete les produits de toutes gammes et on affiche au hasard ces produits
  useEffect(() => {
    setProducts(
      getMergeProducts(
        "produits" in products
          ? products.produits
          : shuffle(productsDatas.allWpProduct.nodes),
        shuffle(productsDatas.allShopifyProduct.nodes)
      )
    )
  }, [])

  return (
    <section className={"featured-products container"}>
      {withSingleTitle ? (
        <h2 className={"single-title"}>Découvrez aussi</h2>
      ) : (
        <>
          <h2 className={"title"}>{products.title}</h2>
          <p className={"description"}>{products.subtitle}</p>
        </>
      )}
      <Swiper
        slidesPerView={2.15}
        spaceBetween={24}
        breakpoints={{
          1024: {
            slidesPerView: 4,
            spaceBetweenSlides: 16,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetweenSlides: 8,
          },
        }}
      >
        {Products.map((product, i) => {
          return (
            <SwiperSlide tag="div" key={i}>
              <ScrollAnimation
                animateIn="fadeInBottom"
                duration={1}
                delay={150*i}
              >
                <Product {...product[0]} />
              </ScrollAnimation>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Link to={ROUTES.SHOP} title={"Tout voir"} className={"link link--black"}>
        Tout voir
      </Link>
    </section>
  )
}

export default Products
